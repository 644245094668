import React from "react";

const PlusMinusErrorComponent = (props) => {
    const show = (props.plus !== null && props.minus !== null);
    const showPlusMinusSign = show && Math.abs(props.plus) === Math.abs(props.minus);

    return show
        ? <div className={props.className + " plus-minus-error-component--root inline-block rounded-full text-xs"}>
            <div className="flex flex-col h-full justify-around items-center">
                <div className="flex flex-row">
                  (
                  {showPlusMinusSign ? <span>&#xb1;</span> : ''}
                  {!showPlusMinusSign ? '+' : ''}
                  {props.plus}{showPlusMinusSign ? '' : '/' + props.minus}
                  )
                </div>
            </div>
          </div>
        : '';
}

export default PlusMinusErrorComponent;




