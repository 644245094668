
import { useState } from "react";
import { NavLink } from 'react-router-dom';
import { CgMenu } from 'react-icons/cg';

const HeaderComponent = (props) => {

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    /**
     * For small screen menu (opens/closes on click)
     */
    function onMenuButtonClick(e) {
        setIsMenuOpen(!isMenuOpen);
    }

    return (
        <div className={props.className + " header-component--root bg-white flex flex-col"}>
            <div className="flex flex-row-reverse justify-end md:flex-row md:justify-start items-center gap-4 md:gap-8
                px-1
                border-b border-blue-100
                h-full
            ">
                <div className="header-component--logo py-1">
                    <h1 className="text-xl font-semibold break-all">
                        <NavLink exact to={process.env.PUBLIC_URL + '/'}>
                            { (process.env.NODE_ENV !== 'production') ? '['+process.env.NODE_ENV+'] ' : '' }
                            ProperScience.net
                        </NavLink>
                    </h1>
                </div>
                <div className="header-component--menu flex flex-row justify-between">
                    <nav className="h-full">
                        <ul className="hidden md:flex flex-row justify-between XXXgap-4 items-center border rounded-md">
                            <li className="px-4 border-r rounded-tl-md rounded-bl-md">
                                <NavLink exact to={process.env.PUBLIC_URL + '/'} activeClassName="active" className="">Home</NavLink>
                            </li>
                            <li className="px-4 rounded-tr-md rounded-br-md">
                                <NavLink to={process.env.PUBLIC_URL + '/exoplanets'} activeClassName="active">Exoplanets Search</NavLink>
                            </li>
                        </ul>
                        <div onClick={(e) => onMenuButtonClick(e)}
                            className={(isMenuOpen ? "bg-blue-300 " : '') + "md:hidden border rounded-md p-2 cursor-pointer"}
                        >
                            <CgMenu />
                        </div>
                    </nav>
                </div>
            </div>

            {/* The small screen menu (opened by clicking menu icon */}
            <div className={(isMenuOpen ? '' : 'hidden') + " font-semibold transition duration-500"}>
                <NavLink exact to={process.env.PUBLIC_URL + '/'} activeClassName="active"
                    className="block bg-black text-white hover:bg-blue-300"
                    onClick={() => setIsMenuOpen(false)}
                >
                    Home
                </NavLink>
                <NavLink to={process.env.PUBLIC_URL + '/exoplanets'} activeClassName="active"
                    className="block bg-black text-white hover:bg-blue-300"
                    onClick={() => setIsMenuOpen(false)}
                >
                    Exoplanets Search
                </NavLink>

            </div>
        </div>
    );
}

export default HeaderComponent;



