
import React, {useState, useEffect} from "react";
import axios from 'axios';
import ExoplanetSearchResultComponent, { ExoplanetSearchResultPlaceholderComponent } from '../ExoplanetSearch/ExoplanetSearchResultComponent.js';
import ExoplanetTapQueryComponent from '../ExoplanetSearch/ExoplanetTapQueryComponent.js';

const ExoplanetSearchComponent = (props) => {
  const [planets, setPlanets] = useState([]);
  const [apiRequestUrl, setApiRequestUrl] = useState('');
  const [loading, setLoading] = useState(false);

  function onUpdateButtonClick(e) {

    setLoading(true);
    axios.post(
      process.env.REACT_APP_API_URL + '/api/fetchNasaExoplanetsData',
      { url: apiRequestUrl }
    ).then((response) => {
      setPlanets(response.data);
      setLoading(false);
    }).catch((response) => {
console.log("ERROR ERROR ");
console.log("response: ", response);
    });

  }

  const onExoplanetTapQueryUpdate = function(url) {
      setApiRequestUrl(url);
  };

  // Set page title
  useEffect(() => {
    document.title = "Exoplanets Search :: ProperScience.net";
  }, []);

  return (
    <div className={`expolanet-search-component--root bg-white p-2 shadow-proper rounded-md
        ${loading ? "cursor-wait" : "cursor-normal"}
      `}
    >
      <h2 className="font-bold text-xl">(Confirmed) Exoplanet Search</h2>
			<p className="text-xs">
				Uses the <a href="https://exoplanetarchive.ipac.caltech.edu/docs/API_PS_columns.html" target="_blank">NASA Exoplanet Archive</a>
			</p>
      <div className="nasa-api-test-1-component--controls bg-blue-100 my-2 p-2 rounded-lg shadow">
        <div className="nasa-api-test-1-component--controls--query flex flex-col">
          <ExoplanetTapQueryComponent onUpdate={e => onExoplanetTapQueryUpdate(e)} />
          <div className="text-center">
            <button className="test-query-1 apply--filters-submit-button"
              onClick={onUpdateButtonClick}
              disabled={loading ? true : false}
            >
              Update
            </button>
          </div>
        </div>
      </div>
      <div className="nasa-api-test-1-component--results my-2 p-2 rounded-lg shadow-proper">
        <h2 className="font-bold text-lg border-b-2 border-blue-400">Results (<small>{planets.length}</small>)</h2>
        {loading 
          ? [1,2,3].map((num, key) => <ExoplanetSearchResultPlaceholderComponent key={key} />)
          : <ul>
                {planets.map((planet, index) => <li key={index}><ExoplanetSearchResultComponent result={planet} /></li>)}
            </ul>
        }
      </div>
    </div>
  );
}
ExoplanetSearchComponent.defaultProps = {
};

export default ExoplanetSearchComponent;

// # vim: ts=2 sw=2
