import './App.css';
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from 'react-router-dom';
import HeaderComponent from './components/HeaderComponent';
import ExoplanetSearchComponent from './components/ExoplanetSearch/ExoplanetSearchComponent.js';
import HomePageComponent from './components/Home/HomePageComponent.js';
import {NotificationContainer} from 'react-notifications';

export default function App() {
	return (
		<Router>
			<div className="App
                bg-black
                min-h-screen
                max-w-7xl mx-auto
                px-0 sm:px-2 lg:px-6
            ">
                <header className="App-header">
                    <HeaderComponent className="bg-white" />
                </header>
                <NotificationContainer />
                <main className="
                    bg-gray-100
                    flex flex-col
                    max-w-7xl mx-auto
                    px-1 sm:px-2 md:px-4 lg:px-6
                    py-4
                ">
                    <Switch>
                        <Route path={process.env.PUBLIC_URL + '/exoplanets'}>
                            <ExoplanetSearchComponent />
                        </Route>
                        <Route path={process.env.PUBLIC_URL + '/'}>
                            <HomePageComponent />
                        </Route>
                    </Switch>
                </main>
			</div>
		</Router>
	);
};


