
import React from "react";

const VideoContentItemComponent = (props) => {

  return (
    <div className={props.className + " video-content-item-component--root flex flex-col justify-between h-full"}>
      <h3 className="font-bold text-xl">{props.result.data.title}</h3>
      <div>
        <a href={props.result.data.url} rel="noreferrer" target="_blank">
          <img className="float-right ml-2 w-24" src={props.result.data.thumbnail_image_link} alt="video thumbnail" />
          <h3 className="text-sm">{props.result.title}</h3>
        </a>
      </div>
      <div className="pt-1">
        <hr className="h-2" />
        <p className="text-xs">
          {
              props.result.data.channel
                ? (<a href={props.result.data.channel} rel="noreferrer" target="_blank">{props.result.data.author}</a>)
                : props.result.data.author
          }
        </p>
      </div>
    </div>
  );
};
VideoContentItemComponent.defaultProps = {
    className: '',
    result: null,
};

export default VideoContentItemComponent;

const VideoContentItemPlaceholderComponent = (props) => {
    return (
        <div className="animate-pulse cursor-not-allowed border border-blue-200 shadow rounded-md p-4 w-full mx-auto XXXmy-2">
            <div className="flex flex-row space-x-4">
                <div className="details w-6/12 space-y-2">
                    <div className="h-4 bg-blue-300 rounded w-3/6 mb-4"></div>
                    <div className="h-4 bg-blue-300 rounded w-5/6"></div>
                    <div className="h-4 bg-blue-300 rounded w-4/6"></div>
                </div>
                <div className="image w-6/12 space-y-2">
                    <div className="h-4 bg-blue-300 h-5/6"></div>
                </div>
            </div>
            <hr className="XXXborder-t my-2 bg-blue-300" />
            <div className="h-3 bg-blue-300 rounded w-5/6"></div>
        </div>
    );
};

export { VideoContentItemPlaceholderComponent };

// # vim: ts=2 sw=2
