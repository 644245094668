
import React, { Component } from 'react'
import axios from 'axios';
import VideoContentItemComponent, { VideoContentItemPlaceholderComponent } from './VideoContentItemComponent';

class ContentItemsComponent extends Component {

    state = {
        loading: false,
        contentItems: [],
    };

    componentDidMount() {
        this.loadContentItems();
    }

    loadContentItems() {
        this.setState({ ...this.state, loading: true });
        axios.get(
          process.env.REACT_APP_API_URL + '/api/content-items'
        ).then((response) => {
          this.setState({ ...this.state, contentItems: response.data.data, loading: false });
        }).catch((response) => {
console.log("ERROR ERROR ");
console.log("response: ", response);
            this.setState({ ...this.state, loading: false });
        });
    }

    render() {
        return (
            <div className={this.props.className + " content-items-component--root"}>
                <h2>Videos</h2>
                <ul className="flex flex-row flex-wrap">
                    {
                        this.state.loading
                            ?   [1,2,3,4,5,6,7,8,9,10,11].map((num, key) =>
                                    <li key={key} className="XXXp-2 mr-2 mt-2 w-full sm:w-5/12 md:4/12 lg:w-3/12 flex-grow-0 bg-white shadow rounded-sm">
                                        <VideoContentItemPlaceholderComponent key={key} />
                                    </li>
                                )
                            :   this.state.contentItems.map((contentItem, index) => (
                                    <li key={index} className="p-2 mr-2 mt-2 w-full sm:w-5/12 md:4/12 lg:w-3/12 flex-grow-0 bg-white shadow rounded-sm">
                                        <VideoContentItemComponent result={contentItem} />
                                    </li>
                                ))
                    }
                </ul>
            </div>
        );
    }
}

export default ContentItemsComponent;

