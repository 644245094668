import React from "react";
import PlusMinusErrorComponent from './PlusMinusErrorComponent';
import ExoplanetDiagramComponent from './ExoplanetDiagramComponent';

/**
 * Component for a piece of data for the planet or star.
 */
function Field(props) {
console.log("in Field()");
console.log("props: ", props);

    const unitsName = props.units && props.unitsName ? props.unitsName : '';

    return (
        <div className="exoplanet-search-result-component--field flex flex-row py-0.5">
            <span className="font-bold w-32">{props.label}:&nbsp;</span>
            <div>
                {(props.value !== null)
                    ? <>
                            <span title={unitsName}>{props.value} {props.units}</span>
                            <PlusMinusErrorComponent className="mx-2" plus={props.errorPlus} minus={props.errorMinus} />
                      </>
                    : <span></span>
                }
            </div>
        </div>
    );
}
Field.defaultProps = {
    label: null,
    fieldName: null,
    value: null,
    errorPlus: null,
    errorMinus: null,
    units: null,
    unitsName: null,
};


/**
 * HTML entity definitions.
 */
const HtmlEntities = {
    earth: { code: 'Earth', hexCode: '2295', description: 'Earth', },
    sun: { code: 'Sun', hexCode: '2609', description: 'Sun', },
};

function HtmlEntity(props) {
    const entityStr = {__html: '&#x' + HtmlEntities[props.code].hexCode + ';'};

    return (
        <span dangerouslySetInnerHTML={entityStr}></span>
    );
}
HtmlEntity.defaultProps = {
    code: null,
};


const ExoplanetSearchResultComponent = (props) => {

    return (
        <div className="exoplanet-search-result-component--root text-sm bg-white p-2 rounded-md shadow-proper my-2">
            <div className="flex flex-col md:flex-row">
                <div className="planet w-full md:w-4/12">
                    <h2 className="font-bold text-lg border-b border-black">Planet</h2>
                    <Field label="Name" value={props.result.pl_name}></Field>
                    <Field label="Mass" value={props.result.pl_masse} errorPlus={props.result.pl_masseerr1} errorMinus={props.result.pl_masseerr2}
                        units={<span>M<sub><HtmlEntity code="earth" /></sub></span>} unitsName="Earth masses"
                    >
                    </Field>
                    <Field label="Radius" value={props.result.pl_rade} errorPlus={props.result.pl_radeerr1} errorMinus={props.result.pl_radeerr2}
                        units={<span>R<sub><HtmlEntity code="earth" /></sub></span>} unitsName="Earth radiuses"
                    >
                    </Field>
                    <Field label="Orbital Period" value={props.result.pl_orbper} errorPlus={props.result.pl_orbpererr1} errorMinus={props.result.pl_orbpererr2} units="days"></Field>
                    <Field label="Semi-major Axis" value={props.result.pl_orbsmax} errorPlus={props.result.pl_orbsmaxerr1} errorMinus={props.result.pl_orbsmaxerr2}
                        units="AU" unitsName="Astronomical Units (1 AU = distance from Sun to Earth)"
                    >
                    </Field>
                    <Field label="RA" value={props.result.ra}></Field>
                    <Field label="Dec" value={props.result.dec}></Field>
                    <Field label="Temperature" value={props.result.pl_eqt} errorPlus={props.result.pl_eqterr1} errorMinus={props.result.pl_eqterr2}
                        units="K"
                    >
                    </Field>
                </div>
                <div className="star w-full md:w-4/12 px-1 rounded-sm">
                    <div className="bg-yellow-100 p-1 h-full">
                        <h2 className="font-bold text-lg border-b border-black">Host star</h2>
                        <Field label="Name" value={props.result.hostname}></Field>
                        <Field label="Mass" value={props.result.st_mass} errorPlus={props.result.st_masserr1} errorMinus={props.result.st_masserr2}
                            units={<span>M<sub><HtmlEntity code="sun" /></sub></span>} unitsName="Solar masses"
                        >
                        </Field>
                        <Field label="Luminosity" value={props.result.st_lum} errorPlus={props.result.st_lumerr1} errorMinus={props.result.st_lumerr2}
                            units={<span>L<sub><HtmlEntity code="sun" /></sub></span>} unitsName="log(Solar luminosities)"
                        >
                        </Field>
                        <Field label="Spectral Type" value={props.result.st_spectype}></Field>
                        <Field label="Radius" value={props.result.st_rad} errorPlus={props.result.st_raderr1} errorMinus={props.result.st_raderr2}
                            units={<span>R<sub><HtmlEntity code="sun" /></sub></span>} unitsName="Solar radii"
                        ></Field>
                        <Field label={<span>T<sub>eff</sub></span>} value={props.result.st_teff} errorPlus={props.result.st_tefferr1} errorMinus={props.result.st_tefferr2} units="K"></Field>
                    </div>
                </div>
                <div className="diagram-container w-full md:w-4/12">
                    <div className="h-full flex-flex-row justify-center items-center">
                        <ExoplanetDiagramComponent result={props.result} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ExoplanetSearchResultComponent;

function ExoplanetSearchResultPlaceholderComponent(props) {
    return (
        <div className="border border-blue-200 shadow rounded-md p-4 w-full mx-auto my-2">
            <div className="animate-pulse flex flex-row space-x-4">
                <div className="planet w-4/12 space-y-2">
                    <div className="h-4 bg-blue-300 rounded w-2/6 mb-4"></div>
                    <div className="h-4 bg-blue-300 rounded w-5/6"></div>
                    <div className="h-4 bg-blue-300 rounded w-4/6"></div>
                    <div className="h-4 bg-blue-300 rounded w-4/6"></div>
                    <div className="h-4 bg-blue-300 rounded w-5/6"></div>
                </div>
                <div className="star w-4/12 space-y-2">
                    <div className="h-4 bg-blue-300 rounded w-2/6 mb-4"></div>
                    <div className="h-4 bg-blue-300 rounded w-5/6"></div>
                    <div className="h-4 bg-blue-300 rounded w-4/6"></div>
                    <div className="h-4 bg-blue-300 rounded w-5/6"></div>
                </div>
                <div className="diagram flex flex-row justify-center items-center w-4/12">
                    <div className="w-6/12 flex justify-center items-center">
                        <div className="rounded-full bg-blue-300 h-6 w-6"></div>
                    </div>
                    <div className="w-6/12 flex justify-center items-center">
                    <div className="rounded-full bg-blue-300 h-10 w-10"></div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export { ExoplanetSearchResultPlaceholderComponent };

