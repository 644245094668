import React, { Component } from 'react'

class MinMaxInputComponent extends Component {

    render() {
        return (
            <div className={this.props.className + " flex flex-col bg-blue-200 pl-1 pr-2 mr-2 shadow"}>
                <div className="flex flex-row items-center">
                    <label className="font-bold">{this.props.label}:</label>
                    <div className="text-xs font-semibold ml-1 my-1 rounded-sm">
                        Min:
                        <input type="number" value={this.props.minValue}
                            min={this.props.inputMin} max={this.props.inputMax} step={this.props.inputStep}
                            onChange={event => this.props.onMinChange(event)}
                            className="w-16 text-center ml-1 rounded-sm"
                        />
                    </div>
                    <div className="text-xs font-semibold ml-1 my-1 rounded-sm">
                        Max:
                        <input type="number" value={this.props.maxValue}
                            min={this.props.inputMin} max={this.props.inputMax} step={this.props.inputStep}
                            onChange={event => this.props.onMaxChange(event)}
                            className="w-16 text-center ml-1 rounded-sm"
                        />
                    </div>
                </div>
                {this.props.units
                    ?   <div className="text-xs text-center"> ({this.props.units}) </div>
                    :   ''}
            </div>
        );
    }
}

MinMaxInputComponent.defaultProps = {
    label: '',
    minValue: 1,
    maxValue: 1,
    inputMin: "0",
    inputMax: "10",
    onMinChange: null,
    onMaxChange: null,
};

export default MinMaxInputComponent;

