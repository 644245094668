
import React, { Component } from 'react'
import ContentItemsComponent from '../ContentItems/ContentItemsComponent';

class HomePageComponent extends Component {

    constructor(props) {
        super();
        this.loading = false;
    }

    componentDidMount() {
        document.title = "Home :: ProperScience.net";
    }

    render() {
        return (
            <div className={this.props.className + " home-page-component--root"}>
                {/* <h1 className="text-lg font-semibold">Home</h1> */}
                <ContentItemsComponent />
            </div>
        );
    }
}

export default HomePageComponent;

