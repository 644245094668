
import React, { Component } from 'react'

const earthWidthPercent = 5;

class ExoplanetDiagramComponent extends Component {

    render() {
        return (
            <div className="diagram relative w-full h-full flex flex-row justify-center items-center">
                <svg version="1.1" baseProfile="full" xlmns="http://www/w3/org/2000/svg"
                    width="100%" height="100%"
                    className="mx-auto"
                >
                    <rect width="100%" height="100%" fill="black"></rect>
                    <circle cx="25%" cy="50%" r={earthWidthPercent + '%'} fill="blue"></circle>
                    <text x="25%" y="65%" fontSize="80%" textAnchor="middle" fill="white">Earth</text>

                    <circle cx="75%" cy="50%" r={this.props.result.pl_rade * earthWidthPercent + '%'} fill="gray"></circle>
                    <text x="75%" y="65%" fontSize="80%" textAnchor="middle" fill="white">{this.props.result.pl_name}</text>
                </svg>
            </div>
        );
    }
}

ExoplanetDiagramComponent.defaultProps = {
    result: null,
};

export default ExoplanetDiagramComponent;


