import React, {useState, useEffect} from "react";
import MinMaxInputComponent from './MinMaxInputComponent.js';
import { GrClipboard } from 'react-icons/gr';
import {NotificationManager} from 'react-notifications';

const selectFields = [
    // START planet
    'pl_name',
    'ra', 'dec',
    'pl_masse', 'pl_masseerr1', 'pl_masseerr2',
    'pl_rade', 'pl_radeerr1', 'pl_radeerr2',
    'pl_orbper', 'pl_orbpererr1', 'pl_orbpererr2',
    'pl_orbsmax', 'pl_orbsmaxerr1', 'pl_orbsmaxerr2',
    'pl_eqt', 'pl_eqterr1', 'pl_eqterr2',
    // END planet

    // START star
    'hostname',
    //'hd_name', 'hip_name', 'tic_id', 'gaia_id',
    'st_mass', 'st_masserr1', 'st_masserr2',
    'st_lum', 'st_lumerr1', 'st_lumerr2',
    'st_spectype',
    'st_rad', 'st_raderr1', 'st_raderr2',
    'st_teff', 'st_tefferr1', 'st_tefferr2',
    // END star

//    'sy_snum', 'sy_pnum',
];
const selectFieldsStr = selectFields.join(',');

const ExoplanetTapQueryComponent = (props) => {

    const API_URL = "https://exoplanetarchive.ipac.caltech.edu/TAP/sync";

    const [minEarthMass, setMinEarthMass] = useState(props.minEarthMass);
    const [maxEarthMass, setMaxEarthMass] = useState(props.maxEarthMass);
    const [minEarthRadius, setMinEarthRadius] = useState(props.minEarthRadius);
    const [maxEarthRadius, setMaxEarthRadius] = useState(props.maxEarthRadius);
    const [minEquilibriumTemp, setMinEquilibriumTemp] = useState(props.minEquilibriumTemp);
    const [maxEquilibriumTemp, setMaxEquilibriumTemp] = useState(props.maxEquilibriumTemp);
    const [apiRequestUrl, setApiRequestUrl] = useState(null);

    function onMinEarthMassChange(event) {
        setMinEarthMass(event.target.value);
    }
    function onMaxEarthMassChange(event) {
        setMaxEarthMass(event.target.value);
    }
    function onMinEarthRadiusChange(event) {
        setMinEarthRadius(event.target.value);
    }
    function onMaxEarthRadiusChange(event) {
        setMaxEarthRadius(event.target.value);
    }
    function onMinEquilibriumTempChange(event) {
        setMinEquilibriumTemp(event.target.value);
    }
    function onMaxEquilibriumTempChange(event) {
        setMaxEquilibriumTemp(event.target.value);
    }

    useEffect(
        () => {
            function buildQuery() {
                return ``
                    + `select+${selectFieldsStr}+from+ps`
                    + `+where+upper(soltype)+like+'%CONF%'`
                    + `+and+pl_masse+between+${minEarthMass}+and+${maxEarthMass}`
                    + `+and+pl_rade+between+${minEarthRadius}+and+${maxEarthRadius}`
                    + `+and+pl_eqt+between+${minEquilibriumTemp}+and+${maxEquilibriumTemp}`
                    + `&format=json`
                ;
            }

            setApiRequestUrl(`${API_URL}?query=${buildQuery()}`);
            if (typeof props.onUpdate === 'function') {
                props.onUpdate(apiRequestUrl);
            }
        },
        [
            props,
            minEarthMass,
            maxEarthMass,
            minEarthRadius,
            maxEarthRadius,
            minEquilibriumTemp,
            maxEquilibriumTemp,
            apiRequestUrl,
        ]
    );

    function onCopyRequestToClipboardButtonClick(e) {
        navigator.clipboard.writeText(apiRequestUrl);
        NotificationManager.success("Request copied to clipboard");
    }

    return (
        <div className="exoplanet-tap-query-component--root rounded-md my-1">
            <div className="exoplanet-tap-query-component--controls mb-2 flex flex-row flex-wrap justify-start w-full">
                <MinMaxInputComponent label="Mass" className="my-1 sm:my-0"
                    minValue={minEarthMass} maxValue={maxEarthMass} onMinChange={onMinEarthMassChange} onMaxChange={onMaxEarthMassChange}
                    inputMin="0.01" inputMax="10" inputStep="0.1" units="Earth masses"
                />
                <MinMaxInputComponent label="Radius" className="my-1 sm:my-0"
                    minValue={minEarthRadius} maxValue={maxEarthRadius} onMinChange={onMinEarthRadiusChange} onMaxChange={onMaxEarthRadiusChange}
                    inputMin="0.01" inputMax="10" inputStep="0.1" units="Earth radiuses"
                />
                <MinMaxInputComponent label="Temperature" className="my-1 sm:my-0"
                    minValue={minEquilibriumTemp} maxValue={maxEquilibriumTemp} onMinChange={onMinEquilibriumTempChange} onMaxChange={onMaxEquilibriumTempChange}
                    inputMin="0" inputMax="" inputStep="20" units="Kelvin"
                />
            </div>
            {/*
            <div className="w-full text-xs break-all mt-4 my-2 px-2">
                Request: {apiRequestUrl}
            </div>
            */}
            <div className="w-full text-xs break-all mt-4 XXXmy-2 XXXpx-2 flex flex-row XXXspace-x-2 justify-between items-center">
                <div>
                    Request:
                </div>
                <div className="flex-grow XXXw-full px-2">
                    <input type="text" readOnly value={apiRequestUrl ? apiRequestUrl : ''} className="w-full" />
                </div>
                <span className="XXXml-4 h-full XXXhover:bg-blue-300 hover:cursor-pointer"
                    onClick={(e) => onCopyRequestToClipboardButtonClick(e)}
                >
                    <GrClipboard className="text-lg cursor-pointer" />
                </span>
            </div>
        </div>
    );
}

ExoplanetTapQueryComponent.defaultProps = {
    minEarthMass: 0.25,
    maxEarthMass: 50,
    minEarthRadius: 0.25,
    maxEarthRadius: 20.0,
    minEquilibriumTemp: 200,
    maxEquilibriumTemp: 400,
    onUpdate: null,
};

export default ExoplanetTapQueryComponent;




